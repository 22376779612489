import React from "react";
import { useRef } from "react";

export const AlertMassegHook = () => {
  const ref = useRef();
  const showAlert = () => {
    if (ref.current) {
      ref.current.click();
    }
  };
  const alertMassge = (data, classNAme, close) => {
    return (
      <>
        <button
          type="button"
          ref={ref}
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          {" "}
        </button>

        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className={`modal-dialog modal-dialog-centered ${classNAme}`}>
            <div className="modal-content">
              <div className="modal-header border-0">
                {!close && (
                  <button
                    type="button"
                    className="btn-close border border-2 fs-6 rounded-3"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                )}
              </div>
              <div className="modal-body"> {data}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return [alertMassge, showAlert];
};
