import React from "react";
import "./style.css";
import { AddCart, Move_To_Wish_List } from "../../services/action";
import { AddCartHooks } from "../../Hook/AddCartHooks";
import { CiSquareRemove } from "react-icons/ci";
import { AddFavHook } from "../../Hook/AddFavHook";

export default function CartWishList({ data }) {
  const [addfavProduct] = AddFavHook();
  const [addCart] = AddCartHooks("Carts", AddCart);
  return (
    <div className="row CartWishList  shadow my-3 rounded-4">
      <div className="col-12 col-sm-5     ">
        <img
          src={data.image}
          height={252}
          className="w-100  shadow rounded-4"
          alt=""
        />
      </div>
      <div className="col-12 col-sm-7            d-flex flex-column justify-content-between">
        <p
          className="fs-5     d-flex  align-items-center justify-content-between
"
        >
          {data.name}
          <button onClick={() => addfavProduct(data)} className="btn  ">
            <CiSquareRemove size={30} />
          </button>
        </p>

        <d className="mb-0"> Status : In Stock </d>

        <div className="d-flex my-4 flex-wrap  d-flex  align-items-center justify-content-between">
          {" "}
          <span className="fw-bold fs-2"> {data.final_price} L.E </span>
          <button
            onClick={() => {
              addCart(data);
              addfavProduct(data);
            }}
            className=" btn second_btn my-4 "
          >
            {" "}
            Add to card
          </button>
        </div>
      </div>
    </div>
  );
}
