import React from "react";
import SelectInput from "../../lib/SelectInput";
import { t } from "i18next";

export default function InputSelect({
  value,
  setvalue,
  label,
  name,
  option,
  placeholder,
}) {
  return (
    <>
      <div className="mb-3">
        <label htmlFor={name} className="form-label fs-5">
          <span className="text-red fs-4 requert_star">*</span> {t(label)}
        </label>
        <SelectInput
          setvalue={setvalue}
          value={value}
          option={option}
          placeholder={placeholder}
        />
      </div>
    </>
  );
}
