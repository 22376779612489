import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
export default function InputPhoneCodeLib({ setvalue, value }) {
  return (
    <PhoneInput
      country={"sa"}
      value={value}
      inputStyle={{ width: "100%", height: "45px", borderRadius: "9px" }}
      enableSearch={true}
      buttonclassName={"button_Code"}
      inputclassName={"form-control-lg w-100"}
      onChange={(phone) => {
        setvalue({ target: { value: phone, name: "phone" } });
      }}
    />
  );
}
