import React from "react";
import Select from "react-select";

export default function SelectInput({
  option,
  setvalue,
  placeholder,
  defouldValue,
  isMulti,
}) {
  return (
    <Select
      className="basic-single"
      classNames="test"
      classNamePrefix={"select"}
      isSearchable={true}
      isDisabled={option && option.length < 1}
      options={option}
      isMulti={isMulti}
      isClearable={true}
      placeholder={placeholder && placeholder}
      value={defouldValue && defouldValue}
      onChange={(data) => data && setvalue(data)}
    />
  );
}
