import React from "react";
import "./style.css";
import InputTextLabel from "../../components/Inputs/InputTextLabel";
import { useState } from "react";
import InputPasswordLabel from "../../components/Inputs/InputPasswordLabel";
import InputPhoneCode from "../../components/Inputs/InputPhoneCode";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../services/action";
import { useEffect } from "react";
import { useCallback } from "react";
import { AlertMassegHook } from "../../Hook/AlertMassegHook";
import ErrorMassge from "../../components/AlertMassegs/ErrorMassge";
import SuccessfullyMassge from "../../components/AlertMassegs/SuccessfullyMassge";
import { t } from "i18next";
import LinksCustom from "../../components/Links/LinksCustom";
export default function RegisterNewCustomer() {
  const [valueAll, setvalueAll] = useState({
    userName: "",
    password: "",
    phone: "",
  });
  const [state, setState] = useState(false);
  const [massge, setmassge] = useState("");
  const dispatch = useDispatch();
  const setvalue = (e) => {
    setvalueAll({
      ...valueAll,
      [e.target.name]: e.target.value,
    });
  };
  const [alertMassge, showAlert] = AlertMassegHook();

  const hendling = useCallback(
    (e) => {
      e.preventDefault();
      let data = {
        first_name: valueAll.userName,
        phone: valueAll.phone,
        password: valueAll.password,
        user_type: 1,
      };
      setState(true);
      setmassge("");
      dispatch(register(data));
    },
    [valueAll, dispatch]
  );
  const register_data = useSelector((state) => state.register);

  useEffect(() => {
    setState(false);
    if (register_data && register_data.status) {
      showAlert();
      dispatch({ type: "register", register: "" });
    } else if (register_data && register_data.status === false) {
      showAlert();
      register_data.data && register_data.data.password
        ? setmassge(t("Wrong Password Format"))
        : setmassge(register_data.message);
      dispatch({ type: "register", register: "" });
    }
  }, [register_data, dispatch]);

  let disbaltButton = () =>
    valueAll.userName === "" ||
    valueAll.phone.length < 9 ||
    valueAll.password.length < 6 ||
    state;
  return (
    <>
      {alertMassge(
        massge ? (
          <ErrorMassge massge={massge} showAlert={showAlert} />
        ) : (
          <SuccessfullyMassge showAlert={showAlert} />
        )
      )}
      <section className="container-xxl ">
        <h1 className="py-5 text-center fw-bold title_login">
          {t("Register New Customer")}
        </h1>
        <form
          onSubmit={hendling}
          className="  aut_form_with shadow-sm my-5 rounded-4"
        >
          <InputTextLabel
            required={true}
            value={valueAll.userName}
            setvalue={setvalue}
            label={"User name"}
            type={"text"}
            name={"userName"}
            classInput={"form-control-lg"}
          />
          <InputPhoneCode
            required={true}
            value={valueAll.phone}
            setvalue={setvalue}
            label={"Phone number"}
            classInput={"form-control-lg"}
          />
          <InputPasswordLabel
            required={true}
            value={valueAll.password}
            setvalue={setvalue}
            label={"Password"}
            type={"password"}
            name={"password"}
            registration={true}
            error={
              register_data.data && register_data.data.password ? true : false
            }
            classInput={"form-control-lg"}
          />

          <div className="text-center mt-5">
            <button
              disabled={disbaltButton()}
              type="submit"
              className="btn rounded-3 main_btn"
            >
              {t("Register")}
            </button>
            <h5 className="mt-5 mb-3">
              {t("Already Have An Account?")}

              <LinksCustom
                label={"Log In"}
                path={"/Login"}
                classCustom={"main_color fw-bold  text-decoration-none "}
              />
            </h5>
          </div>
        </form>
      </section>
    </>
  );
}
