import React from "react";
import Breadcrumbs from "../../Layout/Breadcrumbs/Breadcrumbs";
import CartShoping from "../../components/CartShoping/CartShoping";
import "./style.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
export default function CartPage() {
  let carts = useSelector((state) => state.Carts);
  const navgite = useNavigate();
  let total = 0;
  let quantity = 0;
  if (carts) {
    total = carts.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.final_price * currentValue.quantity,
      0
    );
    quantity = carts.reduce(
      (accumulator, currentValue) => accumulator + currentValue.quantity,
      0
    );
  }
  let paths = [
    { path: "homePage", title: "Home" },
    { path: "Categories", title: "All Items" },
    { path: "CartPage", title: "Cart" },
  ];

  return (
    <div className="container-xxl CartPage">
      <Breadcrumbs paths={paths} />
      {carts &&
        carts.map((item, index) => (
          <div key={item.id} className="my-1">
            <CartShoping data={item} />
            {carts.length !== index + 1 && <hr className="my-3" />}
          </div>
        ))}
      {(!carts || quantity === 0) && (
        <h3 className="text-center">{t("There are no products")}</h3>
      )}
      <hr className="custom_hr" />
      <div className="d-flex      justify-content-end">
        <h2 className="fw-bold  ">
          {t("Subtotal")} ({quantity} {t("item")}): {total} EGP
        </h2>
      </div>
      <div
        className="text-center my-5 d-flex
    align-items-center
    justify-content-center"
      >
        <button
          onClick={() =>
            navgite(`/ShippingInformation${window.location.search}`)
          }
          disabled={!carts || quantity === 0}
          className="main_btn rounded-4 py-1  fs-3 lh-1 p-0 px-0"
        >
          {t("Buy now")}
        </button>
      </div>
    </div>
  );
}
