import React from "react";
import Slider from "react-slick";
const MultipleSlider = ({ children, check }) => {
  const settings = {
    centerMode: false,
    infinite: false,
    centerPadding: "20px", // Changed to 30px for 20px padding on each side
    className: "center",
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return <>{check && <Slider {...settings}>{children}</Slider>}</>;
};
export default MultipleSlider;
