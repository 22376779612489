import React from "react";
import Slider from "react-slick";
import "./style.css";
export default function SingleSlider({ children }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="SingleSlider">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}
