import React from "react";
import "./style.css";
export default function CategoryCartSplashPage({
  onselect,
  title,
  color,
  data,
}) {
  const backgroundImageUrl = data && data.image;
  const encodedBackgroundImageUrl =
    backgroundImageUrl && encodeURI(backgroundImageUrl);
  return (
    <div
      onClick={() => onselect(data.id)}
      className={`card  rounded-3 border-0 shadow m-2 Img_div_catigory  CategoryCartSplashPage `}
      style={{
        backgroundImage: `url(${encodedBackgroundImageUrl})`,
      }}
    >
      <div
        className={`color_div_catigory   ${
          color ? "light" : "main"
        }   align-items-center   justify-content-center`}
      >
        {data.title.slice(0, 13)}
      </div>
    </div>
  );
}
