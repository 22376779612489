import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ValueSearch } from "../../services/action";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import LinksCustom from "../Links/LinksCustom";

export default function InputSearchWithIcon() {
  const dispatch = useDispatch();
  const [valueInput, setvalueInput] = useState('');

  const onHendling = (e) => {
    e.preventDefault();
    dispatch(ValueSearch(e.target.value));
  };
  const Navigate = useNavigate();
  const handleKeyDown = (e) => {

    Navigate(`/Categories${window.location.search}`);
  };

  return (
    <div className="position-relative">
      <LinksCustom
        idcustom={"linksCustom"}
        path={"/Categories"}
        classCustom={"btn_searsh  position-absolute border-0 h-100 mx-3 mt-2"}
      >
        <FaSearch color="#ACACAC" size={25} />
      </LinksCustom>
      <input
        value={valueInput}
        onChange={(e) => {
          setvalueInput(e.target.value);
          onHendling(e);
        }}
        onKeyDown={handleKeyDown}
        className="form-control-lg w-100 border-0 px-5"
        type="text"
        placeholder={t("Search")}
        aria-label="default input example"
      />
    </div>
  );
}
