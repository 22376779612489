import { t } from "i18next";
import React from "react";
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const ToggleIconArrowWithTitle = ({ idaction, title }) => {
  const [Active, setActive] = useState(true);
  return (
    <>
      <h4
        data-bs-target={idaction}
        data-bs-toggle="collapse"
        className="ArrowToggleIcon d-flex  align-items-center  justify-content-between"
        onClick={() => setActive(!Active)}
      >
        {t(title)}
        {Active ? <IoIosArrowUp size={30} /> : <IoIosArrowDown size={30} />}
      </h4>
    </>
  );
};
export default ToggleIconArrowWithTitle;
