import React, { useEffect, useState } from "react";
import "./style.css";
import ToggleIconArrow from "../ArrowToggleIcon/ToggleIconArrow";
import FilterBrands from "./FilterBrands";
import FilterCatigory from "./FilterCatigory";
import { useDispatch, useSelector } from "react-redux";
import { All_brands, All_categories } from "../../services/action";
import TokenLang from "../../Hook/TokenLang";
import { t } from "i18next";

const AsideCategories = ({
  setcatigoryFilter,
  handleFormChange,
  reset,
  catigoryFilter,
  catigotyId,
  BrandFilter,
}) => {
  const [moreCatgory, setmoreCatgory] = useState(5);
  const [morebrand, setmorebrand] = useState(5);

  const dispatch = useDispatch();
  const [token, lang] = TokenLang();

  useEffect(() => {
    dispatch(All_categories(lang, moreCatgory, catigotyId));
  }, [moreCatgory, lang, catigotyId]);
  useEffect(() => {
    dispatch(All_brands(lang, morebrand));
  }, [morebrand, lang]);

  let AllCategories = useSelector((state) => state.All_categories);
  let AllBrands = useSelector((state) => state.All_brands);

  return (
    <aside className="AsideCategories   p-2">
      <div className="accordion" id="accordionPanelsStayParend">
        <div className="accordion-item">
          <div id="items_parant" className="accordion-collapse collapse show">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="title-part">{t("All Categories")}</span>
                  <ToggleIconArrow idaction={`#item_Catigyrt`} />
                </div>
                <div
                  id="item_Catigyrt"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body p-0 py-3">
                    {AllCategories && AllCategories.status && (
                      <FilterCatigory
                        lang={lang}
                        catigoryFilter={catigoryFilter}
                        setcatigoryFilter={setcatigoryFilter}
                        data={AllCategories.data.data}
                      />
                    )}
                  </div>
                </div>
                <div className="">
                  {AllCategories.status &&
                    AllCategories.data.total > moreCatgory && (
                      <button
                        onClick={() => setmoreCatgory((state) => state + 5)}
                        className="btn main_color fs-6 mx-2 text-decoration-underline"
                      >
                        {t("See More")}
                      </button>
                    )}
                </div>
                <hr />
              </div>
              <div className="accordion-item">
                <div className="d-flex align-items-center justify-content-between">
                  <span className="title-part">{t("Brands")}</span>
                  <ToggleIconArrow idaction={`#item_Brands`} />
                </div>

                <div
                  id="item_Brands"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body p-0">
                    {AllBrands && AllBrands.status && (
                      <FilterBrands
                        handleFormChange={handleFormChange}
                        data={AllBrands.data.data}
                        BrandFilter={BrandFilter}
                      />
                    )}
                  </div>
                </div>
                <div className="text-end">
                  {AllBrands.status && AllBrands.data.total > morebrand && (
                    <button
                      onClick={() => setmorebrand((state) => state + 5)}
                      className="btn main_color fs-6 mx-2 text-decoration-underline"
                    >
                      {t("See More")}
                    </button>
                  )}
                </div>
                <hr />
              </div>

              <div className="my-3 text-center">
                <button
                  onClick={reset}
                  className="second_btn rounded-3 px-4 py-2"
                >
                  {" "}
                  {t("Reset")}{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <TopIconAside /> */}
    </aside>
  );
};

export default AsideCategories;
