import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import InputPasswordLabel from "../../components/Inputs/InputPasswordLabel";
import InputPhoneCode from "../../components/Inputs/InputPhoneCode";
import { login } from "../../services/action";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ErrorMassge from "../../components/AlertMassegs/ErrorMassge";
import { AlertMassegHook } from "../../Hook/AlertMassegHook";
import { t } from "i18next";
import TokenLang from "../../Hook/TokenLang";
import LinksCustom from "../../components/Links/LinksCustom";
export default function Login() {
  const [valueAll, setvalueAll] = useState({ phone: "", password: "" });
  const [massge, setmassge] = useState("");
  const [alertMassge, showAlert] = AlertMassegHook();
  const [state, setState] = useState(false);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const setvalue = (e) => {
    setvalueAll({
      ...valueAll,
      [e.target.name]: e.target.value,
    });
  };
  const [token, lang] = TokenLang();

  const hendling = useCallback(
    (e) => {
      e.preventDefault();
      let data = {
        phone: valueAll.phone,
        password: valueAll.password,
      };
      setState(true);
      setmassge("");
      dispatch(login(data, lang));
    },
    [valueAll, dispatch]
  );
  const login_data = useSelector((state) => state.login);
  useEffect(() => {
    setState(false);
    if (login_data && login_data.status) {
      localStorage.setItem("login", JSON.stringify(login_data));
      Navigate("/");
      dispatch({ type: "login", login: "" });
    } else if (login_data && login_data.status === false) {
      setmassge(login_data.message);
      showAlert();
      dispatch({ type: "login", login: "" });
    }
  }, [login_data, dispatch, Navigate]);

  let disbaltButton = () =>
    valueAll.phone.length < 9 || valueAll.password.length < 6 || state;
  return (
    <>
      {alertMassge(<ErrorMassge massge={massge} showAlert={showAlert} />)}
      <section className="container-xxl ">
        <h1 className="py-5 text-center fw-bold title_login">{t("Log In")}</h1>
        <form
          onSubmit={hendling}
          className="  aut_form_with shadow-sm my-5 rounded-4"
        >
          <InputPhoneCode
            required={true}
            value={valueAll.phone}
            setvalue={setvalue}
            label={"Phone number"}
            classInput={"form-control-lg"}
          />
          <InputPasswordLabel
            required={true}
            value={valueAll.password}
            setvalue={setvalue}
            label={"Password"}
            type={"password"}
            name={"password"}
            classInput={"form-control-lg"}
          />

          <LinksCustom
            label={"Forgot Password ?"}
            path={"/ResetPassword"}
            classCustom={" main_color text-decoration-none  "}
          />
          <div className="text-center mt-5">
            <button
              disabled={disbaltButton()}
              type="submit"
              className="btn rounded-3 main_btn"
            >
              {t("Log In")}
            </button>
            <h5 className="mt-5 mb-3">
              {t("New Customer ?")}{" "}
              <LinksCustom
                label={"Register Now"}
                path={"/RegisterNewCustomer"}
                classCustom={"main_color fw-bold  text-decoration-none "}
              />
            </h5>
          </div>
        </form>
      </section>
    </>
  );
}
