import React from "react";
import "./style.css";
import { t } from "i18next";
export default function FooterSplashPage() {
  let data = ["New parts", "Used parts", "Best price", "Fast delivery"];
  return (
    <>
      <h2 className="fw-bold py-3 h2_color">{t("Ters Values")}</h2>

      <div className="d-flex   justify-content-between FooterSplashPage">
        {data &&
          data.map((item, index) => (
            <div key={index} className="d-flex align-items-center my-2">
              <h3 className="mx-3 fw-bold">{t(item)}</h3>
            </div>
          ))}
      </div>
    </>
  );
}
