import React from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import InputPhoneCode from "../../components/Inputs/InputPhoneCode";
import { reset_password } from "../../services/action";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ErrorMassge from "../../components/AlertMassegs/ErrorMassge";
import { AlertMassegHook } from "../../Hook/AlertMassegHook";
import { t } from "i18next";
export default function ResetPassword() {
  const [valueAll, setvalueAll] = useState({ phone: "" });
  const [massge, setmassge] = useState("");
  const [alertMassge, showAlert] = AlertMassegHook();

  const [state, setState] = useState(false);

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const setvalue = (e) => {
    setvalueAll({
      ...valueAll,
      [e.target.name]: e.target.value,
    });
  };
  const hendling = useCallback(
    (e) => {
      e.preventDefault();
      let data = {
        phone: valueAll.phone,
      };
      setState(true);
      setmassge("");
      dispatch(reset_password(data));
    },
    [valueAll, dispatch]
  );
  const resetPassword = useSelector((state) => state.reset_password);

  useEffect(() => {
    setState(false);
    if (resetPassword && resetPassword.status) {
      Navigate("/NewPassword");
    } else if (resetPassword && resetPassword.status === false) {
      setmassge(resetPassword.message);
      showAlert();
      dispatch({ type: "reset_password", reset_password: "" });
    }
  }, [resetPassword, dispatch, Navigate]);

  let disbaltButton = () => valueAll.phone.length < 9 || state;

  return (
    <>
      {alertMassge(<ErrorMassge massge={massge} showAlert={showAlert} />)}
      <section className="container-xxl ">
        <h1 className="py-5 text-center fw-bold title_login">{t("Log In")}</h1>
        <form
          onSubmit={hendling}
          className="  aut_form_with shadow-sm my-5 rounded-4"
        >
          <div className="text-center">
            <h3>{t("Forgot Password ?")}</h3>
            <div className="py-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="151.805"
                height="109.292"
                viewBox="0 0 151.805 109.292"
              >
                <g
                  id="Group_10718"
                  data-name="Group 10718"
                  transform="translate(0 0)"
                >
                  <ellipse
                    id="Ellipse_1329"
                    data-name="Ellipse 1329"
                    cx="34.967"
                    cy="34.967"
                    rx="34.967"
                    ry="34.967"
                    transform="translate(81.871 0)"
                    fill="#455a64"
                  />
                  <ellipse
                    id="Ellipse_1330"
                    data-name="Ellipse 1330"
                    cx="23.89"
                    cy="23.89"
                    rx="23.89"
                    ry="23.89"
                    transform="translate(0 61.513)"
                    fill="#ebebeb"
                  />
                  <path
                    id="Path_33576"
                    data-name="Path 33576"
                    d="M322.952,165.755v25.052h-7.093V165.755c0-9.538-7.244-17.295-16.148-17.295s-16.148,7.757-16.148,17.295v25.052H276.47V165.755c0-13.658,10.428-24.765,23.241-24.765S322.952,152.1,322.952,165.755Z"
                    transform="translate(-227.438 -137.308)"
                    fill="#c7c7c7"
                  />
                  <path
                    id="Path_33577"
                    data-name="Path 33577"
                    d="M276.47,161.72l7.093.287v18.276H276.47Z"
                    transform="translate(-227.437 -126.753)"
                    fill="#a6a6a6"
                  />
                  <path
                    id="Path_33578"
                    data-name="Path 33578"
                    d="M309.663,163.082v16.661H302.57V162.78Z"
                    transform="translate(-214.149 -126.213)"
                    fill="#a6a6a6"
                  />
                  <rect
                    id="Rectangle_17904"
                    data-name="Rectangle 17904"
                    width="64.652"
                    height="58.645"
                    transform="translate(39.947 39.252)"
                    fill="#ffc727"
                  />
                  <path
                    id="Path_33579"
                    data-name="Path 33579"
                    d="M296.648,189l2.716,12.843h-11.5L290.582,189a5.75,5.75,0,1,1,6.037,0Z"
                    transform="translate(-221.644 -118.283)"
                    fill="#263238"
                  />
                </g>
              </svg>
            </div>

            <p>{t("Enter the phone number associated with your account")}</p>
          </div>
          <InputPhoneCode
            required={true}
            value={valueAll.phone}
            setvalue={setvalue}
            label={"Phone number"}
            classInput={"form-control-lg"}
          />

          <div className="text-center mt-5">
            <button
              disabled={disbaltButton()}
              type="submit"
              className="btn rounded-3 main_btn"
            >
              {t("Send")}
            </button>
            <h5 className="mt-5 mb-3">
              {t("Didn`T Recieve Code ?")}{" "}
              <Link
                disabled={disbaltButton()}
                className="main_color fw-bold  text-decoration-none "
              >
                {" "}
                {t("Re-Send")}
              </Link>
            </h5>
          </div>
        </form>
      </section>
    </>
  );
}
