import * as React from "react";

import TrackingOrder from "../../components/TrackingOrder/TrackingOrder";
import Breadcrumbs from "../../Layout/Breadcrumbs/Breadcrumbs";
import FollowOrderTrakening from "../../components/FollowOrderTrakening/FollowOrderTrakening";

export default function FollowTrackingOrder() {
  let activeSteps = 2;

  return (
    <>
      <div className="container-xxl">
        <Breadcrumbs />
      </div>
      <TrackingOrder activeStep={activeSteps} />
      <FollowOrderTrakening />
    </>
  );
}
