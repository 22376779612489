import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
export default function CategoriesCard({ data }) {
  // const replaceImage = (error) => {
  //   error.onerror = null; // prevents looping
  //   // check if the logo image exists
  //   const img = new Image();
  //   img.onerror = () => {
  //     // if the logo image is not found, use a placeholder image or an empty string
  //     error.target.src = ""; // or ''
  //   };
  //   img.src = test_img;

  //   // if the logo image is found, use it as the replacement image
  //   error.target.src = test_img;
  // };
  const [searchParams, setSearchParams] = useSearchParams();
  const catigotyId = searchParams.get("Catigoty_id");
  const Navigate = useNavigate();
  const onselect = (e) => {
    Navigate(
      `/Categories?subCategoryId=${e}${
        catigotyId ? `&Catigoty_id=${catigotyId}` : ""
      }`
    );
  };
  return (
    <div className="CategoriesCard m-auto mx-2  ">
      <div onClick={() => onselect(data.id)} className="img_slider shadow p-2">
        <img src={data.image} className="m-auto h-100  w-100" alt="" />
      </div>

      <h4 className="text-center mt-3">{data.title}</h4>
    </div>
  );
}
