import React from "react";
import "./style.css";
import InputTextLabel from "../../components/Inputs/InputTextLabel";
import { useState } from "react";
import InputPasswordLabel from "../../components/Inputs/InputPasswordLabel";
import InputPhoneCode from "../../components/Inputs/InputPhoneCode";
import { useDispatch, useSelector } from "react-redux";
import { forgot_password } from "../../services/action";
import { useEffect } from "react";
import { useCallback } from "react";
import { AlertMassegHook } from "../../Hook/AlertMassegHook";
import ErrorMassge from "../../components/AlertMassegs/ErrorMassge";
import SuccessfullyMassge from "../../components/AlertMassegs/SuccessfullyMassge";
import { t } from "i18next";
export default function NewPassword() {
  const [valueAll, setvalueAll] = useState({
    code: "",
    password: "",
    phone: "",
  });
  const [state, setState] = useState(false);
  const [massge, setmassge] = useState("");
  const dispatch = useDispatch();
  const setvalue = (e) => {
    setvalueAll({
      ...valueAll,
      [e.target.name]: e.target.value,
    });
  };
  const [alertMassge, showAlert] = AlertMassegHook();

  const hendling = useCallback(
    (e) => {
      e.preventDefault();
      let data = {
        code: valueAll.code,
        phone: valueAll.phone,
        new_password: valueAll.password,
        user_type: 1,
      };
      setState(true);
      setmassge("");
      dispatch(forgot_password(data));
    },
    [valueAll, dispatch]
  );
  const forgotPassword = useSelector((state) => state.forgot_password);

  useEffect(() => {
    setState(false);
    if (forgotPassword && forgotPassword.success === 1) {
      // Navigate("/Login");
      showAlert();
      dispatch({ type: "forgot_password", forgot_password: "" });
    } else if (forgotPassword && forgotPassword.success === 0) {
      showAlert();
      forgotPassword.data && forgotPassword.data.password
        ? setmassge("Wrong Password Format")
        : setmassge(forgotPassword.msg);

      dispatch({ type: "forgot_password", forgot_password: "" });
    }
  }, [forgotPassword, dispatch]);

  let disbaltButton = () =>
    valueAll.code < 4 ||
    valueAll.phone.length < 9 ||
    valueAll.password.length < 6 ||
    state;
  return (
    <>
      {alertMassge(
        massge ? (
          <ErrorMassge massge={massge} showAlert={showAlert} />
        ) : (
          <SuccessfullyMassge
            msg={"Account Restored Successfully"}
            showAlert={showAlert}
          />
        )
      )}
      <section className="container-xxl ">
        <h1 className="py-5 text-center fw-bold title_login">{t("Log In")}</h1>
        <form
          onSubmit={hendling}
          className="  aut_form_with shadow-sm my-5 rounded-4"
        >
          <div className="text-center">
            <h3 className="mb-5">{t("Forgot Password ?")}</h3>

            <p>{t("Enter the phone number associated with your account")}</p>
          </div>
          <InputPhoneCode
            required={true}
            value={valueAll.phone}
            setvalue={setvalue}
            label={"Phone number"}
            classInput={"form-control-lg"}
          />
          <InputTextLabel
            required={true}
            value={valueAll.code}
            setvalue={setvalue}
            label={"Confirmation code"}
            type={"text"}
            name={"code"}
            classInput={"form-control-lg"}
          />
          <InputPasswordLabel
            required={true}
            value={valueAll.password}
            setvalue={setvalue}
            label={"New Password"}
            type={"password"}
            name={"password"}
            registration={true}
            error={
              forgotPassword.data && forgotPassword.data.password ? true : false
            }
            classInput={"form-control-lg"}
          />

          <div className="text-center mt-5">
            <button
              disabled={disbaltButton()}
              type="submit"
              className="btn rounded-3 main_btn"
            >
              {t("Register")}
            </button>
          </div>
        </form>
      </section>
    </>
  );
}
