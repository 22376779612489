import React, { useEffect } from "react";
import CardProduct from "../Cards/CardProduct";
import { AddCartHooks } from "../../Hook/AddCartHooks";
import { useDispatch, useSelector } from "react-redux";
import { productsList } from "../../services/action";
import { t } from "i18next";
import LinksCustom from "../Links/LinksCustom";
import SpinnerLoding from "../../lib/SpinnerLoding/SpinnerLoding";

export default function BestSellingSection({ lang, token, catigotyId }) {
  const [addCart, isShowingAlert] = AddCartHooks();
  const dispatch = useDispatch();
  const update = useSelector((state) => state.favProducts);

  // Output: 44
  useEffect(() => {
    dispatch(productsList(lang, token, catigotyId));
  }, [update, catigotyId]);
  const prodeucts = useSelector((state) => state.productsList);

  return (
    <>
      <div className="container-xxl py-5">
        <div className="  d-flex  align-items-center  justify-content-between pb-4 ">
          <h3 className="title_3"> {t("Best Selling Items")}</h3>

          <LinksCustom
            label={"All Items"}
            path={"/Categories"}
            classCustom={"main_color link_all_parts "}
          />
        </div>

        <div className="row row-cols-2 row-cols-lg-3 row-cols-xl-4 g-2">
          {prodeucts && prodeucts.status ? (
            prodeucts.data.map((item) => (
              <CardProduct key={item.id} addCart={addCart} data={item} />
            ))
          ) : (
            <SpinnerLoding />
          )}
        </div>
        {prodeucts && prodeucts.status && prodeucts.data.length === 0 && (
          <h3 className="my-5 text-center">{t("Not Items")} </h3>
        )}
      </div>
    </>
  );
}
