import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsCartPlus } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import LinksCustom from "../../components/Links/LinksCustom";

export default function FavCartIcon({ color }) {
  let carts = useSelector((state) => state.Carts);
  let quantity = 0;
  if (carts) {
    quantity = carts.reduce(
      (accumulator, currentValue) => accumulator + currentValue.quantity,
      0
    );
  }
  const favProductListQty = useSelector((state) => state.QTY_favProductList);

  return (
    <div className="d-flex justify-content-around ">
      <LinksCustom
        path={"/CartPage"}
        classCustom={
          "btn icon_navbar border border-2 position-relative  d-flex mx-1 p-0"
        }
      >
        <BsCartPlus color={color ? color : "#fff"} size={35} />
        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
          {quantity < 100 ? quantity : "99+"}
          <span className="visually-hidden">unread messages</span>
        </span>
      </LinksCustom>

      <LinksCustom
        path={"/Wishlist"}
        classCustom={
          "btn icon_navbar  border border-2 position-relative mx-2 d-flex  p-0 "
        }
      >
        <AiOutlineHeart color={color ? color : "#fff"} size={40} />

        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
          {favProductListQty < 100 ? favProductListQty : "99+"}
          <span className="visually-hidden">unread messages</span>
        </span>
      </LinksCustom>
    </div>
  );
}
