import React from "react";
import { Link } from "react-router-dom";
import { BsTelephoneFill } from "react-icons/bs";
import "./style.css";
import ToggleIconArrowWithTitle from "../../components/ArrowToggleIcon/ToggleIconArrowWithTitle";
import { t } from "i18next";
export default function Footer() {
  return (
    <>
      <footer>
        <div className="container-xxl py-3">
          <div className="row ">
            <div className="col-12 col-sm-4    ">
              <span className="title_mbel">
                <ToggleIconArrowWithTitle
                  idaction={"#panelsStayOpen-AboutTers"}
                  title={"About Ters"}
                />
              </span>
              <h4 className="title_web">{t("About Ters")}</h4>

              <div
                id="panelsStayOpen-AboutTers"
                className="accordion-collapse collapse show"
              >
                <div className="d-flex flex-column">
                  <Link>{t("Careers")}</Link>
                  <Link>{t("International Brands")}</Link>
                  <Link>{t("Exchange and Privacy policies")}</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 d-flex  flex-column  ">
              <Link className="mx-2">{t("legal")}</Link>
              <Link className="mx-2">{t("Privacy policy")}</Link>
              <Link className="mx-2"> {t("Terms and conditions")}</Link>
              <Link className="mx-2">{t("Guidelines for Reviewers")}</Link>
            </div>
            <div className="col-12 col-sm-4  d-flex  flex-column ">
              <Link className="mx-2">{t("System status")}</Link>
              <Link className="mx-2">{t("Modern Slavery Statement")}</Link>
            </div>
          </div>

          <hr />

          <div className="   text-center">
            {t("All rights are reserved to")}{" "}
            <span className="fw-bold"> {t("TERS")}</span> &copy; 2023
          </div>
        </div>
      </footer>
    </>
  );
}
