import React, { memo, useEffect, useState } from "react";
import "./style.css";
import { MdOutlineAdd } from "react-icons/md";
import { IoIosRemove } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { AddCart } from "../../services/action";
import { AddCartHooks } from "../../Hook/AddCartHooks";

const CountAddREmove = memo(({ data, minCount = 1 }) => {
  const dispatch = useDispatch();
  const [quntaty, setquntaty] = useState("");
  const cartsData = useSelector((state) => state.Carts);
  const carShoping = cartsData ? cartsData : [];
  const [addCart] = AddCartHooks("Carts", AddCart);
  let indexP = carShoping.findIndex((item) => item.id === data.id);
  useEffect(() => {
    setquntaty(
      (carShoping[indexP] && indexP >= 0 && carShoping[indexP].quantity) ||
        carShoping.quantity
    );
  }, [carShoping]);

  const addItem = () => {
    const updatedCart = [...carShoping];
    updatedCart[indexP] = {
      ...updatedCart[indexP],
      quantity: updatedCart[indexP].quantity + 1,
    };
    dispatch(AddCart(updatedCart));
  };

  const removeItem = () => {
    const quantitylocal =
      carShoping[indexP].minimum === 0 ? 1 : carShoping[indexP].minimum;
    if (carShoping[indexP].quantity > quantitylocal) {
      const updatedCart = [...carShoping];
      updatedCart[indexP] = {
        ...updatedCart[indexP],
        quantity: updatedCart[indexP].quantity - 1,
      };
      dispatch(AddCart(updatedCart));
    }
  };

  const changeInputCountaty = (index, quantity) => {
    const updatedCart = [...carShoping];
    updatedCart[index] = {
      ...updatedCart[index],
      quantity: Number(quantity),
    };
    dispatch(AddCart(updatedCart));
  };

  return (
    <div className="CountAddREmove d-flex ">
      <button
        disabled={indexP === -1 || carShoping[indexP].quantity < 2}
        onClick={() => removeItem(indexP)}
      >
        <IoIosRemove size={30} />
      </button>
      <input
        type="text"
        value={indexP === -1 ? minCount : quntaty}
        onChange={(event) => {
          changeInputCountaty(indexP, event.target.value);
          setquntaty(event.target.value);
        }}
      />
      <button
        onClick={() => {
          indexP === -1 ? addCart(data) : addItem();
        }}
      >
        <MdOutlineAdd size={35} />
      </button>
    </div>
  );
});
export default CountAddREmove;
