import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import React, { Component } from "react";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export class MapWithSearsh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapCenter: { lat: props.latitude, lng: props.longitude },
      places: [],
      address: "",
    };
  }
  handleChange = (address) => {
    this.setState({ address });
  };
  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.props.setlatitude(latLng.lat);
        this.props.setlongitude(latLng.lng);
        this.setState({
          mapCenter: { lat: latLng.lat, lng: latLng.lng },
        });
      });
  };
  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    const { google, latitude, longitude } = this.props;
    const { mapCenter, places } = this.state;
    const style = {
      width: "100%",
      height: "300px",
    };
    const moveMarker = (props, marker, e) => {
      this.setState({
        mapCenter: { lat: e.latLng.lat(), lng: e.latLng.lng() },
      });
      this.props.setlatitude(e.latLng.lat());
      this.props.setlongitude(e.latLng.lng()); // get the new coordinates after drag end
    };
    return (
      <>
        <Map
          google={google}
          zoom={14}
          style={style}
          initialCenter={{ lat: latitude, lng: longitude }}
          center={mapCenter}
          onClick={this.onMapClicked}
          onReady={this.fetchPlaces}
        >
          <Marker
            title="Location"
            id={1}
            position={mapCenter}
            draggable={true}
            onDragend={moveMarker}
            onClick={this.onMarkerClick}
          />

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <div>
              <h1>{this.state.selectedPlace.name}</h1>
            </div>
          </InfoWindow>
        </Map>
        <div className="search_input_map  w-100   position-absolute">
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: " حدد موقعك ...",
                    className: "location-search-input  w-75   form-control",
                  })}
                />
                <div className="autocomplete-dropdown-container shadow  mt-1 p-1 w-75 ">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBRR-QRcDqReT8KiMnYUhKc4lQz_ZLx70s",
})(MapWithSearsh);
