import React, { memo } from "react";
import CardProduct from "../Cards/CardProduct";
import { Link } from "react-router-dom";
import MultipleSlider from "../Sliders/MultipleSlider";
import "./style.css";
import { t } from "i18next";
import LinksCustom from "../Links/LinksCustom";
const SimilarParts = memo(({ data, addCart }) => {
  return (
    <div className="  py-5 SimilarParts">
      <div className="  d-flex    justify-content-between pb-4 ">
        <h2 className="fw-bod"> {t("Similar Parts")}</h2>

        <LinksCustom
          label={"All Items"}
          path={"/Categories"}
          classCustom={"main_color fs-5"}
        />
      </div>

      <MultipleSlider check={true}>
        {data &&
          data.map((item) => (
            <CardProduct
              classSlider="auto_hight"
              addCart={addCart}
              data={item}
              key={item.id}
            />
          ))}
      </MultipleSlider>
    </div>
  );
});
export default SimilarParts;
