import React from "react";
import error_icon from "../../asset/img/icon_error.svg";
import { t } from "i18next";

export default function ErrorMassge({ massge, showAlert }) {
  return (
    <div>
      <div className="text-center py-3">
        <img src={error_icon} alt="" />
      </div>
      <h2 className="py-2 text-center">{massge}</h2>
      <div className="text-center pt-2 pb-5">
        <button
          onClick={showAlert}
          className="AsideBorderBtn border-0 fs-3 fw-bold "
        >
          {t("Insert Again")}
        </button>
      </div>
    </div>
  );
}
