import React, { memo } from "react";
import "./style.css";
import SingleSlider from "../Sliders/SingleSlider";
import CheckIFAddedhook from "../../Hook/CheckIFAddedhook";
import { t } from "i18next";
import { AddFavHook } from "../../Hook/AddFavHook";
import CountAddREmove from "../CountAddREmove/CountAddREmove";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { MdFileDownloadDone } from "react-icons/md";

import LinksCustom from "../Links/LinksCustom";
const DetailsProductTop = memo(({ data, addCart }) => {
  const [checkIfAdded] = CheckIFAddedhook();
  const [addfavProduct] = AddFavHook();
  return (
    <div className="row DetailsProductTop">
      <div className="col-12 col-md-6 col-lg-5   position-relative">
        <div className=" position-absolute btn_addFavord">
          <button
            onClick={() => addfavProduct(data)}
            className="add_favorite mx-1 "
          >
            {data.fav_product === 1 ? (
              <AiFillHeart color="#D77804" size={25} />
            ) : (
              <AiOutlineHeart size={25} color="#686868" />
            )}
          </button>
        </div>
        <SingleSlider>
          {data && data.slider_images.length > 0 ? (
            data.slider_images.map((item) => (
              <div className="img_slider rounded-4 " key={item.id}>
                <img
                  src={item.image}
                  className="w-100 h-100 rounded-4 img_slider  shadow "
                  alt=""
                />
              </div>
            ))
          ) : (
            <div className="img_slider rounded-4 ">
              <img
                src={data.image}
                className="w-100 h-100 rounded-4 img_slider  shadow "
                alt=""
              />
            </div>
          )}
        </SingleSlider>
      </div>
      <div className="col-12 col-md-6 col-lg-7 mt-3">
        <p className="fs-5">{data.name}</p>
        <div className="d-flex     align-items-end ">
          <span className="fw-bold fs-2">{data.final_price} L.E </span>
          <div className="main_color mx-2">
            {" "}
            {t("Price include all taxes")}{" "}
          </div>
        </div>
        <div className="d-flex  align-items-center  ">
          <span>{t("Quantity")} </span>{" "}
          <span className="shadow-sm p-2 rounded-3">
            {" "}
            <CountAddREmove
              minCount={data && data.minimum}
              data={data && data}
            />
          </span>
        </div>
        <p className="py-1 mt-4"> {data.desc}</p>
        <div className="d-flex    justify-content-center    justify-content-md-between ">
          <LinksCustom
            label={"Buy now"}
            path={"/CartPage"}
            classCustom={
              " main_btn rounded-3   my-3 text-center text-decoration-none"
            }
            fun={() => addCart(data)}
          />
          {checkIfAdded(data.id) ? (
            <button
              disabled
              className="second_btn     rounded-3  my-3  add_to_cart  mx-2 "
            >
              <MdFileDownloadDone size={50} color="#D37604 " />
            </button>
          ) : (
            <button
              onClick={() => addCart(data)}
              className="second_btn     rounded-3   my-3 add_to_cart  mx-2 "
            >
              {t("Add to cart")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
});
export default DetailsProductTop;
