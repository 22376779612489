import { t } from "i18next";
import React from "react";

export default function PartDetailsProductSecond({ content }) {
  return (
    <>
      <h2 className="fw-bold fs-1 my-4">{t("Part Details")}:</h2>

      <div
        className="overflow-hidden"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </>
  );
}
