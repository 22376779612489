import { useCallback } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AddCart } from "../services/action";

export const AddCartHooks = (typeNAme, funName) => {
  const dispatch = useDispatch();
  const [isShowingAlert, setShowingAlert] = useState(false);
  const addCart = useCallback((iteme) => {
    const quantitylocal = iteme.quantity
      ? iteme.quantity
      : iteme.minimum == 0
      ? 1
      : iteme.minimum;

    const items = JSON.parse(localStorage.getItem(typeNAme));
    const data = {
      ...iteme,
      quantity: Number(quantitylocal),
    };

    if (items) {
      if (items.find((product) => product.id === iteme.id)) {
      } else {
        dispatch(funName([...items, data]));
        setShowingAlert(true);
      }
    } else {
      dispatch(funName([data]));
    }
  }, []);

  return [addCart, isShowingAlert];
};
