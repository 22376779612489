import React, { useEffect } from "react";
import SingleSlider from "../Sliders/SingleSlider";
import BtnProductsCard from "../Cards/BtnProductsCard";
import { sliders_and_banners } from "../../services/action";
import { useDispatch, useSelector } from "react-redux";

export default function SliderBtnProducts({ lang, token }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sliders_and_banners(lang, token, "slider", 0));
  }, [lang]);
  const slider = useSelector((state) => state.sliderHome);

  return (
    <>
      <section className="container-xxl py-2">
        <SingleSlider>
          {slider &&
            slider.status &&
            slider.data.map((item) => (
              <BtnProductsCard key={item.id} data={item} />
            ))}
        </SingleSlider>
      </section>
    </>
  );
}
