import { t, use } from "i18next";
import React, { Fragment } from "react";
import { useState } from "react";
import NavDropdown from "./NavDropdown";
import TokenLang from "../../Hook/TokenLang";
import { parants_categories } from "../../services/action";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

export default function UsedNew() {
  const [wayPay, setwayPay] = useState("New");
  const [textTitle, settextTitle] = useState("");
  const [token, lang] = TokenLang();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(parants_categories(lang));
  }, [lang]);
  let productsCategories = useSelector((state) => state.parants_categories);

  const [searchParams, setSearchParams] = useSearchParams();
  const setCatigory = (e) => {
    setSearchParams({ Catigoty_id: e });
  };
  const ClearCatigory = () => {
    setSearchParams("");
  };
  let idCatgory = searchParams.get("Catigoty_id");
  useEffect(() => {
    if (productsCategories && productsCategories.status && idCatgory) {
      let data = productsCategories.data.filter((item) => item.id == idCatgory);
      if (data) {
        settextTitle(data[0].title);
      }
    } else {
      settextTitle("All Catigory");
    }
  }, [idCatgory, productsCategories]);

  return (
    <NavDropdown title={t(textTitle.slice(0, 14))} classCustom={"main_color"}>
      <li onClick={ClearCatigory} className="li_Catigory_Select">
        {t("All Catigory")}
      </li>
      {productsCategories &&
        productsCategories.status &&
        productsCategories.data.map((state) => (
          <Fragment key={state.id}>
            <hr className="w-75 m-auto my-0" />
            <li
              className="li_Catigory_Select"
              onClick={() => setCatigory(state.id)}
            >
              {state.title}
            </li>
          </Fragment>
        ))}
    </NavDropdown>
  );
}
