import { t } from "i18next";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";

export default function LinksCustom({
  label,
  path,
  classCustom,
  fun,
  children,
  idcustom,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const reset = () => {
    searchParams.delete("brands_id");
    searchParams.delete("subCategoryId");
    setSearchParams(searchParams);
  };
  return (
    <>
      <Link
        id={idcustom && idcustom}
        to={{ pathname: path, search: window.location.search }}
        onClick={fun ? fun : reset}
        className={classCustom}
      >
        {t(label)} {children}
      </Link>
    </>
  );
}
