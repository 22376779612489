import React from "react";
import ToggleIcon from "../ArrowToggleIcon/ToggleIcon";
import "./style.css";

export default function FilterCatigory({
  data,
  setcatigoryFilter,
  lang,
  catigoryFilter,
}) {
  const renderSubCategories = (items) => {
    return items.map((item) => (
      <div key={item.id} className="accordion-item">
        <div
          className={`d-flex align-items-center justify-content-between ${
            catigoryFilter == item.id && "active_border"
          }`}
        >
          <button
            onClick={() => setcatigoryFilter(item.id)}
            className={`btn w-100 ${
              lang === "ar" ? "text-end" : "text-start"
            } btn_hover `}
          >
            <img
              src={item.image}
              width={25}
              height={25}
              alt={item.name}
              className="rounded-2"
            />
            <span className="mx-2"> {item.title} </span>
          </button>
          {item.children && item.children.length > 0 && (
            <ToggleIcon idaction={`#item_${item.id}`} />
          )}
        </div>

        {item.children && item.children.length > 0 && (
          <div id={`item_${item.id}`} className="accordion-collapse collapse">
            <div className="accordion-body p-0">
              {renderSubCategories(item.children)}
            </div>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="accordion subCategories" id="subCategories">
      {data && renderSubCategories(data)}
    </div>
  );
}
