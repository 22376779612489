import React from "react";

export default function NavDropdown({ title, children, classCustom }) {
  return (
    <>
      <div className={` nav-item dropdown`}>
        <span
          className={`${classCustom} nav-link dropdown-toggle`}
          href="#"
          id="navbarDropdownMenuLink"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {title}
        </span>
        <ul
          className="dropdown-menu position-absolute"
          aria-labelledby="navbarDropdownMenuLink"
        >
          {children}
        </ul>
      </div>
    </>
  );
}
