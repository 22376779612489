import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddCart } from "../services/action";

export default function RemoveItemHook() {
  let carts = useSelector((state) => state.Carts);
  const dispatch = useDispatch();
  const removeItem = (id) => {
    if (carts) {
      let updataData = carts.filter((item) => item.id !== id);
      dispatch(AddCart(updataData));
    }
  };
  const removeAll = () => {
    dispatch(AddCart([]));
  };

  return [removeItem, removeAll];
}
