import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { AddfavProducts, deleteItemfavProducts } from "../services/action";
import TokenLang from "./TokenLang";

export const AddFavHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [token] = TokenLang();

  const addfavProduct = useCallback((prodect) => {
    if (token) {
      if (prodect && prodect.fav_product !== 1) {
        dispatch(AddfavProducts(token, { product_id: prodect.id }));
      } else {
        dispatch(deleteItemfavProducts(token, prodect.id));
      }
    } else {
      navigate("/login");
    }
  }, []);

  return [addfavProduct];
};
