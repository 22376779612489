import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import "./style.css";
import { t } from "i18next";
import LinksCustom from "../../components/Links/LinksCustom";
export default function Breadcrumbs({ paths }) {
  // const paths = location.state;
  return (
    <div className="Breadcrumbs fs-5 py-4">
      {paths &&
        paths.map((item, index) => (
          <Fragment key={index}>
            <LinksCustom
              label={item.title}
              path={`/${item.path}`}
              classCustom={"text-decoration-none "}
            />
            {index < paths.length - 1 && (
              <span className="px-2">
                <MdArrowBackIosNew size={25} />
              </span>
            )}
          </Fragment>
        ))}
    </div>
  );
}
