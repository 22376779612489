import React from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import CategoriesSection from "../../components/CategoriesSection/CategoriesSection";
import DashBoardSection from "../../components/DashBoardSection/DashBoardSection";
import SliderBtnProducts from "../../components/SliderBtnProducts/SliderBtnProducts";
import BestSellingSection from "../../components/BestSellingSection/BestSellingSection";
import TokenLang from "../../Hook/TokenLang";
import { useLocation } from "react-router-dom";

export default function HomePage() {
  const [token, lang] = TokenLang();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const catigotyId = searchParams.get("Catigoty_id");
  return (
    <>
      <HeroSection lang={lang} token={token} />
      <DashBoardSection />
      <CategoriesSection catigotyId={catigotyId} />
      <SliderBtnProducts lang={lang} token={token} />
      <BestSellingSection catigotyId={catigotyId} lang={lang} token={token} />
    </>
  );
}
