import React, { useState } from "react";
import "./style.css";
import MainBTN from "../BtnCustom/MainBTN";
import SelectInput from "../../lib/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import TokenLang from "../../Hook/TokenLang";
import { brands } from "../../services/action";
import { useNavigate, useSearchParams } from "react-router-dom";
import { t } from "i18next";
export default function FindParts() {
  const dispatch = useDispatch();
  const [token, lang] = TokenLang();
  const [brandid, setbrandid] = useState();
  useEffect(() => {
    dispatch(brands(lang, 1, ""));
  }, []);
  const navgite = useNavigate();
  let brand_1 = useSelector((state) => state.brand_1);
  let brand_2 = useSelector((state) => state.brand_2);
  let brand_3 = useSelector((state) => state.brand_3);
  let brand_4 = useSelector((state) => state.brand_4);

  let brand_1Options = [];
  let brand_2Options = [];
  let brand_3Options = [];
  let brand_4Options = [];
  if (brand_1.status) {
    brand_1.data.forEach((element) => {
      brand_1Options.push({ label: element.name, value: element.id });
    });
  }

  if (brand_2.status) {
    brand_2.data.forEach((element) => {
      brand_2Options.push({ label: element.name, value: element.id });
    });
  }
  if (brand_3.status) {
    brand_3.data.forEach((element) => {
      brand_3Options.push({ label: element.name, value: element.id });
    });
  }
  if (brand_4.status) {
    brand_3.data.forEach((element) => {
      brand_4Options.push({ label: element.name, value: element.id });
    });
  }

  const getbrand_1 = (e) => {
    dispatch({ type: "brand_2", paylod: [] });
    dispatch({ type: "brand_3", paylod: [] });
    dispatch({ type: "brand_4", paylod: [] });
    dispatch(brands(lang, 2, e.value));

    setbrandid(e.value);
  };
  const getbrand_2 = (e) => {
    dispatch({ type: "brand_3", paylod: [] });
    dispatch({ type: "brand_4", paylod: [] });
    setbrandid(e.value);
    dispatch(brands(lang, 3, e.value));
    setbrandid(e.value);
  };
  const getbrand_3 = (e) => {
    dispatch({ type: "brand_4", paylod: [] });
    dispatch(brands(lang, 4, e.value));
    setbrandid(e.value);
  };
  const getbrand_4 = (e) => {
    setbrandid(e.value);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const catigotyId = searchParams.get("Catigoty_id");
  const FilterByBrand = () => {
    navgite(
      `/Categories?brands_id=[${brandid}]${
        catigotyId ? `&Catigoty_id=${catigotyId}` : ""
      }`
    );
  };
  return (
    <>
      <div className="  find_parts      d-flex flex-column  justify-content-around">
        <h2 className="title_2 fw-bold text-center Color_light">
          {t("Find Parts")}
        </h2>

        <SelectInput
          placeholder={t("--Select--")}
          option={brand_1Options}
          setvalue={getbrand_1}
        />
        <SelectInput
          placeholder={t("--Select--")}
          option={brand_2Options}
          setvalue={getbrand_2}
        />
        <SelectInput
          placeholder={t("--Select--")}
          option={brand_3Options}
          setvalue={getbrand_3}
        />
        <SelectInput
          placeholder={t("--Select--")}
          option={brand_4Options}
          setvalue={getbrand_4}
        />

        <div className="text-center ">
          <button
            disabled={!brandid}
            onClick={FilterByBrand}
            className=" btn main_btn   rounded-4"
          >
            {t("Search")}
          </button>
        </div>
      </div>
    </>
  );
}
