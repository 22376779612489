import React from "react";
import flag_sa from "../../asset/img/flag_sa.svg";
import flag_En from "../../asset/img/flag_En.svg";
import TokenLang from "../../Hook/TokenLang";
import i18next from "i18next";
import "./style.css";
export default function ChangeLang({ customClass }) {
  const [token, lang] = TokenLang();
  const saveChange = () => {
    i18next.changeLanguage(lang == "en" ? "ar" : "en");
    sessionStorage.setItem("lang", JSON.stringify(lang == "en" ? "ar" : "en"));
  };
  return (
    <li className={`${customClass} nav-item lang_flag`} onClick={saveChange}>
      {lang == "ar" ? (
        <div>
          <img src={flag_sa} alt="" className="rounded-5" />
        </div>
      ) : (
        <div>
          <img src={flag_En} alt="" />
        </div>
      )}
    </li>
  );
}
