import React from "react";
export default function BtnProductsCard({ data }) {
  return (
    <>
      <div className="BtnProductsCard position-relative">
        <div className="back_color d-flex flex-column  justify-content-center">
          <div className="row">
            <div className="col-12 col-lg-8  col-xl-6 text-center">
              {data.html && (
                <div
                  className="h-75 mb-5 px-5 content_BtnProductsCard"
                  dangerouslySetInnerHTML={{ __html: data.html }}
                />
              )}
              {data.url && (
                <a
                  href={data.url}
                  target={data.target}
                  className=" btn     btn_link"
                >
                  Shop Now
                </a>
              )}
              ;
            </div>
            <div className="col-12 col-lg-4 col-xl-6"> </div>
          </div>
        </div>
        <img src={data.image} className="w-100" alt="" />
      </div>
    </>
  );
}
