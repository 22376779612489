import React from "react";
import "./style.css";
import { FaCog } from "react-icons/fa";
export default function SpinnerLoding() {
  return (
    <>
      <div className="gear-clock-container position-relative">
        <div className="gear gear-small position-absolute">
          <FaCog className="gear-icon " color="#2e2e2e" size={160} />
        </div>
        <div className="gear gear-medium position-absolute">
          <FaCog size={100} className="gear-icon" color="#63808F" />
        </div>
        <div className="gear gear-large position-absolute">
          <FaCog className="gear-icon" size={130} color="#F79B1B" />
        </div>
      </div>
    </>
  );
}
