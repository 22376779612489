import React from "react";
import { Link } from "react-router-dom";
import CategoriesCard from "../Cards/CategoriesCard";
import MultipleSlider from "../Sliders/MultipleSlider";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { products_categories } from "../../services/action";
import { t } from "i18next";
import TokenLang from "../../Hook/TokenLang";
import LinksCustom from "../Links/LinksCustom";
import SpinnerLoding from "../../lib/SpinnerLoding/SpinnerLoding";

export default function CategoriesSection({ catigotyId }) {
  const dispatch = useDispatch()
  const [token, lang] = TokenLang()
  useEffect(() => {
    dispatch(products_categories(lang, catigotyId))
  }, [dispatch, lang, catigotyId])
  let productsCategories = useSelector(state => state.products_categories)

  return (
    <>
      <div className=" CategoriesSection py-5 ">
        <div className="container-xxl">


          <div className=" d-flex   justify-content-between pb-4  ">
            <h3 className="title_3 mb-3"> {t("Looking For Your Car Parts !")}</h3>

            <LinksCustom label={"All Categories"} path={"/Categories"} classCustom={"main_color  link_all_parts"} />
          </div>
          {productsCategories && productsCategories.status ? <MultipleSlider check={productsCategories && productsCategories.status}>
            {productsCategories && productsCategories.status && productsCategories.data && productsCategories.data.map(item => (
              <CategoriesCard key={item.id} data={item} />
            ))}
          </MultipleSlider> : <SpinnerLoding />}
          {productsCategories && productsCategories.status && productsCategories.data.length === 0 && (
            <h3 className="my-5 text-center">{t("Not Items")} </h3>
          )}
        </div>
      </div>
    </>
  );
}
