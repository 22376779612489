import { useEffect, useState } from "react";
import Breadcrumbs from "../../Layout/Breadcrumbs/Breadcrumbs";
import TrackingOrder from "../../components/TrackingOrder/TrackingOrder";
import PaymentMethodSelect from "../../components/PaymentMethodSelect/PaymentMethodSelect";
import FormShippingInformation from "../../components/FormShippingInformation";

export default function ShippingInformation() {
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // Modern browsers require a returnValue to be set
      event.returnValue = "";

      // Prompt the user with a confirmation dialog
      const message = "Are you sure you want to leave this page?";
      event.returnValue = message; // Gecko, Trident, and Edge
      return message; // WebKit
    };

    // Attach the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  let paths = [
    { path: "homePage", title: "Home" },
    { path: "Categories", title: "All Items" },
    { path: "CartPage", title: "Cart" },
    { path: "ShippingInformation", title: "Payment Details" },
  ];
  return (
    <>
      <div className="container-xxl">
        <Breadcrumbs paths={paths} />
      </div>
      <div className="container-xxl">
        <TrackingOrder activeStep={activeStep} />
        {activeStep === 0 ? (
          <FormShippingInformation
            handleNext={handleNext}
            handleBack={handleBack}
          />
        ) : activeStep === 1 ? (
          <PaymentMethodSelect
            handleNext={handleNext}
            handleBack={handleBack}
          />
        ) : (
          <h2>not page 404</h2>
        )}
      </div>
    </>
  );
}
