import React, { useEffect, useState } from "react";
import AsideCategories from "../../components/AsideCategories/AsideCategories";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { AddCartHooks } from "../../Hook/AddCartHooks";
import TokenLang from "../../Hook/TokenLang";
import { AllproductsList } from "../../services/action";
import Breadcrumbs from "../../Layout/Breadcrumbs/Breadcrumbs";
import PaginateComp from "../../lib/Paginate";
import SpiinnerLoding from "../../lib/SpinnerLoding/SpinnerLoding";

import { useLocation, useSearchParams } from "react-router-dom";
import CardProduct from "../../components/Cards/CardProduct";
import { t } from "i18next";
export default function Categories() {
  const [addCart, isShowingAlert] = AddCartHooks();
  const [state, setstate] = useState(false);
  const location = useLocation();
  const [token, lang] = TokenLang();
  const [numberpage, setnumberPage] = useState(1);
  const [catigoryFilter, setcatigoryFilter] = useState("");
  const [BrandFilter, setBrandFilter] = useState([]);
  const dispatch = useDispatch();
  const updateProduct = useSelector((state) => state.favProducts);
  let searsh = useSelector((state) => state.ValueSearch);
  const [searchParams, setSearchParams] = useSearchParams();

  const catigotyId = searchParams.get("Catigoty_id");
  const subCategoryId = searchParams.get("subCategoryId");

  const brandsId = searchParams.get("brands_id");
  let brandsIdJson = JSON.parse(brandsId);
  let subCatigoty_id = location.state?.subCategoryId;
  useEffect(() => {
    setcatigoryFilter(subCategoryId);
  }, [subCategoryId]);

  useEffect(() => {
    let filter = {
      brand_id: BrandFilter.length > 0 ? BrandFilter : "",
      category_id: catigoryFilter,
      keyword: searsh,
    };

    if (brandsIdJson !== null && BrandFilter.length < 1) {
      filter.brand_id = brandsIdJson;
    }

    if (catigotyId && !catigoryFilter) {
      filter.category_id = catigotyId;
    }
    if (subCategoryId && !catigoryFilter) {
      filter.category_id = subCategoryId;
    }

    if (subCatigoty_id && !catigoryFilter) {
      filter.category_id = subCatigoty_id;
    }
    dispatch(AllproductsList(lang, token, numberpage, filter));
  }, [
    numberpage,
    updateProduct,

    BrandFilter,

    catigotyId,
    catigoryFilter,
    searsh,
    lang,
    state,
  ]);

  const prodeucts = useSelector((state) => state.AllproductsList);
  const handleFormChange = (e) => {
    const value = parseInt(e.target.value);
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add value to the array if it doesn't exist
      if (!BrandFilter.includes(value)) {
        setBrandFilter((prevFilter) => [...prevFilter, value]);
      }
    } else {
      // Remove value from the array if it exists
      setBrandFilter((prevFilter) =>
        prevFilter.filter((item) => item !== value)
      );
    }
  };

  let paths = [
    { path: "homePage", title: "Home" },
    { path: "Categories", title: "All Products" },
  ];
  const reset = () => {
    setBrandFilter([]);
    setcatigoryFilter("");
    setstate(true);
    searchParams.delete("brands_id");
    searchParams.delete("subCategoryId");
    setSearchParams(searchParams);
  };

  return (
    <>
      <section className="container-xxl Categories">
        <Breadcrumbs paths={paths} />
        <div className=" py-3 top_head_dedails">
          <h4>{t("All products")} </h4>
          <div>
            <button
              data-bs-target="#items_parant"
              data-bs-toggle="collapse"
              className="btn p-0"
            >
              <svg
                id="Group_10844"
                data-name="Group 10844"
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
              >
                <g id="Group_10843" data-name="Group 10843">
                  <g id="Component_5_61" data-name="Component 5 – 61">
                    <g
                      id="Rectangle_2246"
                      data-name="Rectangle 2246"
                      fill="none"
                      stroke="#2e2e2e"
                      strokeWidth="1"
                    >
                      <rect width="36" height="36" rx="8" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="35"
                        height="35"
                        rx="7.5"
                        fill="none"
                      />
                    </g>
                  </g>
                </g>
                <path
                  id="Icon_feather-filter"
                  data-name="Icon feather-filter"
                  d="M27,4.5H3l9.6,11.352V23.7l4.8,2.4V15.852Z"
                  transform="translate(4 2.7)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="row">
          <div className=" col-12 col-md-3">
            <div className=" "></div>
            <AsideCategories
              reset={reset}
              BrandFilter={BrandFilter}
              catigotyId={catigotyId}
              catigoryFilter={catigoryFilter}
              setcatigoryFilter={setcatigoryFilter}
              handleFormChange={handleFormChange}
            />
          </div>
          <div className="  col-12 col-md-9">
            <div className="row row-cols-2 row-cols-lg-3 row-cols-xl-4 g-2">
              {prodeucts && prodeucts.status ? (
                prodeucts.data.data.map((item) => (
                  <CardProduct key={item.id} addCart={addCart} data={item} />
                ))
              ) : (
                <SpiinnerLoding />
              )}
            </div>
            {prodeucts &&
              prodeucts.status &&
              prodeucts.data.data.length === 0 && (
                <h3 className="my-5 text-center">{t("Not Items")} </h3>
              )}
            {prodeucts && prodeucts.status && (
              <div
                className="    d-flex
              align-items-center
              justify-content-center my-3"
              >
                {prodeucts.data.total !== 0 && (
                  <PaginateComp
                    setnumberPage={setnumberPage}
                    pageCounts={prodeucts.data.last_page}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
