import React from "react";
import { useState } from "react";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";

const ToggleIconArrow = ({ idaction }) => {
  const [Active, setActive] = useState(true);
  return (
    <>
      <span
        data-bs-target={idaction}
        data-bs-toggle="collapse"
        className="ArrowToggleIcon"
        onClick={() => setActive(!Active)}
      >
        {Active ? <RiArrowUpSFill size={30} /> : <RiArrowDownSFill size={30} />}
      </span>
    </>
  );
};
export default ToggleIconArrow;
