import React from "react";
import successful_icon from "../../asset/img/syccessfuly_icon.svg";
import LinksCustom from "../Links/LinksCustom";

export default function SuccessfullyMassge({ showAlert, msg, btn, close }) {
  return (
    <div>
      <div className="text-center py-3">
        <img src={successful_icon} width={85} height={85} alt="" />
        {!msg && <h2 className="py-2"> Congratulations !</h2>}
      </div>
      <h2 className="py-2 text-center">
        {msg ? msg : "You Registered Successfully"}
      </h2>
      <div className="text-center pt-2 pb-5">
        {btn ? (
          btn
        ) : (
          <LinksCustom
            label={"Login Now"}
            path={"/login"}
            fun={showAlert}
            classCustom={
              "AsideBorderBtn border-0 fs-3 fw-bold text-decoration-none "
            }
          />
        )}
      </div>
    </div>
  );
}
