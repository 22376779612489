import React from "react";
import "./style.css";
import CountAddREmove from "../CountAddREmove/CountAddREmove";
import RemoveItemHook from "../../Hook/RemoveItemHook";
import { AddFavHook } from "../../Hook/AddFavHook";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { t } from "i18next";
export default function CartShoping({ data }) {
  const [removeItem, removeAll] = RemoveItemHook();
  const [addfavProduct] = AddFavHook();

  return (
    <div className="row CartShoping">
      <div className="col-12 col-md-5 col-lg-4     position-relative ">
        <div className="    position-absolute btn_addFavord">
          <button
            onClick={() => addfavProduct(data)}
            className="add_favorite mx-1 "
          >
            {data.fav_product === 1 ? (
              <AiFillHeart color="#D77804" size={25} />
            ) : (
              <AiOutlineHeart size={25} color="#686868" />
            )}
          </button>
        </div>

        <img src={data.image} height={400} className="w-100" alt="" />
      </div>
      <div className="col-12 col-md-7 col-lg-8   mt-3     d-flex flex-column justify-content-between">
        <p className="fs-5 d-flex justify-content-between  flex-column flex-md-row">
          {data.name}
          <span className="fw-bold fs-2"> {data.final_price} L.E </span>
        </p>

        <ul className="custom-list d-flex  flex-column justify-content-between h-100">
          <div>
            <li className="mb-0">
              {" "}
              {t("Status")} : {t("In Stock")}{" "}
            </li>
            <div className="fw-light">{t("MassgPriceHaveTax")}</div>
          </div>

          <li>
            {t("Type")} :{data.product_kind}{" "}
          </li>
          <div>
            <li>{t("Quantity")}</li>
            <div className="d-flex">
              <span className="shadow p-2 rounded-3">
                {" "}
                <CountAddREmove data={data} />
              </span>
            </div>
          </div>
        </ul>
        <div className="div_Button d-flex my-4 flex-wrap">
          <button
            onClick={() => removeItem(data.id)}
            className=" btn AsideBorderBtn my-1  "
          >
            {" "}
            {t("Remove")}
          </button>
          <button
            onClick={async () => {
              await addfavProduct(data);
              removeItem(data.id);
            }}
            className=" btn AsideBorderBtn my-1  hidden_web"
          >
            {" "}
            {t("Move to wish list")}
          </button>
        </div>
      </div>
    </div>
  );
}
