import React from "react";
import "./style.css";
import { t } from "i18next";
export default function DashBoardCard({ icon, title, body }) {
  return (
    <div className="col text-center SimpleSliderCard">
      <div className="card h-100     align-items-center py-4">
        <div className="icon_top     d-flex align-items-center justify-content-center">
          {icon}
        </div>
        <div className="card-body  pb-0">
          <h5 className="card-title fw-bold">{t(title)}</h5>
          <p className="card-text">{t(body)}</p>
        </div>
      </div>
    </div>
  );
}
