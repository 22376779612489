import React from "react";

export default function ItemStep({ activeCheck, endState, data }) {
  const currentTime = new Date(data.add_date);
  const formattedTime = currentTime.toDateString();
  return (
    <>
      <div
        className={`order-track-step ${activeCheck && "active"} ${
          endState && "end_state"
        } `}
      >
        <div className="order-track-status">
          <span className="order-track-status-dot active d-flex    align-items-center justify-content-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25.186"
              height="24.31"
              viewBox="0 0 25.186 24.31"
            >
              <path
                id="Path_18"
                data-name="Path 18"
                d="M162.4,80.4a22.451,22.451,0,0,0-3.424,3.36c-1.744,1.938-3.424,3.941-5.072,5.976-1.744,2.132-3.424,4.264-5.072,6.461-.388.517-.808,1.066-1.2,1.615-1.034-1.518-2.1-3-3.231-4.426-1.551-1.971-4.264.775-2.746,2.714a70.5,70.5,0,0,1,4.361,6.3c.711,1.131,2.714,1.389,3.36,0a22.053,22.053,0,0,1,2.649-4.006c1.486-2,3.037-3.974,4.62-5.912s3.2-3.877,4.878-5.75c.614-.679,1.2-1.357,1.842-2l.743-.743c.259-.258.517-.452.259-.258,2-1.421.065-4.781-1.971-3.327Z"
                transform="translate(-140.621 -79.539)"
                fill="#fff"
                stroke="#fff"
                strokeWidth="1"
              />
            </svg>
          </span>
          <span className="order-track-status-line active"></span>
        </div>
        <div className="order-track-text">
          <p className="order-track-text-stat"> {data.status.name}</p>
          <span className="order-track-text-sub">{formattedTime}</span>
        </div>
      </div>
    </>
  );
}
