import { t } from "i18next";
import React from "react";
import { NavLink } from "react-router-dom";
import NavLinkCustom from "../../components/Links/NavLinkCustom";

export default function NavLinks({ title, path }) {
  return (
    <li className="nav-item">
      <NavLinkCustom label={title} path={path} classCustom={"nav-link "} />
    </li>
  );
}
