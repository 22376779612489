import React, { useEffect } from "react";
import Breadcrumbs from "../../Layout/Breadcrumbs/Breadcrumbs";
import DetailsProductTop from "../../components/DetailsProductTop/DetailsProductTop";
import PartDetailsProductSecond from "../../components/PartDetailsProductSecond/PartDetailsProductSecond";
import SimilarParts from "../../components/SimilarParts/SimilarParts";
import { useDispatch, useSelector } from "react-redux";
import { AddCart, productDetails } from "../../services/action";
import { useParams } from "react-router-dom";
import { AddCartHooks } from "../../Hook/AddCartHooks";
import TokenLang from "../../Hook/TokenLang";
import SpinnerLoding from "../../lib/SpinnerLoding/SpinnerLoding";
import CheckIFAddedhook from "../../Hook/CheckIFAddedhook";
import { AddFavHook } from "../../Hook/AddFavHook";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BsFillCartCheckFill } from "react-icons/bs";
import { MdAddShoppingCart } from "react-icons/md";
import { t } from "i18next";
import "./style.css";
export default function ProductPage() {
  const [token, lang] = TokenLang();
  const dispatch = useDispatch();
  const param = useParams();
  const update = useSelector((state) => state.favProducts);

  useEffect(() => {
    if (param.id) {
      dispatch(productDetails(lang, token, param.id));
    }
  }, [param.id, update, lang]);
  const derailsProdeuct = useSelector((state) => state.productDetails);
  const [addCart] = AddCartHooks("Carts", AddCart);
  let paths = [
    { path: "homePage", title: "Home" },
    { path: "Categories", title: "All Items" },
    {
      path: `Product/${param.id}`,
      title:
        derailsProdeuct.status &&
        derailsProdeuct.status &&
        derailsProdeuct.data[0].name,
    },
  ];
  const [checkIfAdded] = CheckIFAddedhook();
  const [addfavProduct] = AddFavHook();

  return (
    <>
      {derailsProdeuct.status ? (
        <section className="container-xxl">
          <Breadcrumbs paths={paths} />
          <div className=" py-3 top_head_dedails">
            <h4>{t("Product")}</h4>
            <div className="d-flex">
              <button
                onClick={() => addfavProduct(derailsProdeuct.data[0])}
                className="add_favorite mx-1 border-0 "
              >
                {derailsProdeuct.data[0].fav_product === 1 ? (
                  <AiFillHeart color="#D77804" size={30} />
                ) : (
                  <AiOutlineHeart size={30} color="#686868" />
                )}
              </button>
              {checkIfAdded(derailsProdeuct.data[0].id) ? (
                <button
                  disabled
                  className="add_favorite main_color  fs-5 rounded-3 border-0 "
                >
                  <BsFillCartCheckFill size={30} />
                </button>
              ) : (
                <button
                  onClick={() => addCart(derailsProdeuct.data[0])}
                  className="add_favorite border-0  mx-1 "
                >
                  <MdAddShoppingCart size={30} />
                </button>
              )}
            </div>
          </div>
          <DetailsProductTop addCart={addCart} data={derailsProdeuct.data[0]} />
          <hr className="custom_hr" />
          <PartDetailsProductSecond
            content={derailsProdeuct.data[0] && derailsProdeuct.data[0].content}
          />
          <hr className="custom_hr" />

          <SimilarParts
            addCart={addCart}
            data={derailsProdeuct.data[0].productRelation}
          />
        </section>
      ) : (
        <SpinnerLoding />
      )}
    </>
  );
}
