import React from "react";
import "./style.css";
import MultipleSlider from "../Sliders/MultipleSlider";
import DashBoardCard from "../Cards/DashBoardCard";
import { FaUsers, FaFlag } from "react-icons/fa";
import { BsCarFrontFill } from "react-icons/bs";
import { t } from "i18next";

export default function DashBoardSection() {
  const data = [
    {
      icon: <FaUsers />,
      title: "423.254.0",
      disc: "Annual User Trust Our Website",
    },
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="55.588"
          height="55.588"
          viewBox="0 0 55.588 55.588"
        >
          <path
            id="Icon_open-dashboard"
            data-name="Icon open-dashboard"
            d="M27.794,0A27.794,27.794,0,1,0,55.588,27.794,27.876,27.876,0,0,0,27.794,0Zm0,6.948A20.845,20.845,0,1,1,6.948,27.794,20.817,20.817,0,0,1,27.794,6.948Zm0,6.948a3.474,3.474,0,1,0,3.474,3.474A3.44,3.44,0,0,0,27.794,13.9ZM16.259,20.845a3.474,3.474,0,0,0-1.32,5.837l6.323,6.323a7.056,7.056,0,0,0-.417,1.737,6.948,6.948,0,1,0,6.948-6.948,7.056,7.056,0,0,0-1.737.417l-6.323-6.323a3.474,3.474,0,0,0-3.057-1.112,3.474,3.474,0,0,0-.417,0Zm21.957,0a3.474,3.474,0,1,0,3.474,3.474A3.44,3.44,0,0,0,38.217,20.845Z"
            fill="#d77804"
          />
        </svg>
      ),
      title: "+50.00",
      disc: "More Than 50 Success Partner",
    },
    {
      icon: <FaFlag />,
      title: "12 Country",
      disc: "Ters Products Reach More Than 12 Country",
    },
    {
      icon: <BsCarFrontFill />,
      title: "All Car Brand",
      disc: "We Provide Our Customers With All Car Brands Spare Parts",
    },
  ];
  return (
    <>
      <div className="DashBoardSlider_back ">
        <div className="container-xxl">
          <h3 className="title_3">
            {" "}
            4 {t("Reasons to shop from")}{" "}
            <span className="main_color">{t("Ters")}</span>{" "}
          </h3>
          <MultipleSlider check={data}>
            {data &&
              data.map((item, index) => (
                <DashBoardCard
                  key={index}
                  title={item.title}
                  body={item.disc}
                  icon={item.icon}
                />
              ))}
          </MultipleSlider>
        </div>
      </div>
    </>
  );
}
