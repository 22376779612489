import React from "react";
import { useSelector } from "react-redux";

export default function CheckIFAddedhook() {
  const carts = useSelector((state) => state.Carts);
  const checkIfAdded = (id) => {
    if (carts !== null) {
      return carts.some((item) => item.id == id);
    }
  };
  return [checkIfAdded];
}
