import React from "react";
import Breadcrumbs from "../../Layout/Breadcrumbs/Breadcrumbs";
import "./style.css";
import { useSelector } from "react-redux";
import CartWishList from "../../components/CartWishList/CartWishList";
import { t } from "i18next";

export default function Wishlist() {
  let user_favProductList = useSelector((state) => state.user_favProductList);

  return (
    <div className="container-xxl CartPage">
      <Breadcrumbs />
      {user_favProductList && user_favProductList.status ? (
        user_favProductList.data &&
        user_favProductList.data.map((item) => (
          <div key={item.id} className="my-1">
            <CartWishList data={item} />
          </div>
        ))
      ) : (
        <h3 className="my-5 text-center">{t("Not Items")}</h3>
      )}
    </div>
  );
}
