import React from "react";
// import test_img from "/logo.svg";
import NavDropdown from "./NavDropdown";

export default function DropUser({ name, nameUser, onlogout }) {
  return (
    <NavDropdown
      title={
        <span className="main_color">
          {" "}
          <img
            src={"/logo.svg"}
            width={30}
            height={30}
            className="rounded-pill img_user "
            alt="test"
          />{" "}
          {name && nameUser}
        </span>
      }
    >
      <li>
        <button onClick={onlogout} className="dropdown-item">
          Logout
        </button>
      </li>
    </NavDropdown>
  );
}
