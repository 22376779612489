import { t } from "i18next";
import React, { useState } from "react";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
export default function InputPasswordLabel({
  value,
  setvalue,
  label,
  type,
  name,
  classInput,
  registration,
  error,
}) {
  const [show, setshow] = useState(false);
  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label fs-5">
        <span className="text-red fs-4 requert_star">*</span> {t(label)}
      </label>
      <div className="position-relative">
        <span className="    position-absolute checked_icon ">
          {show ? (
            <span onClick={() => setshow(false)}>
              <AiFillEye
                color={value.length >= 6 ? "#308805" : "#C7C5C5"}
                size={23}
              />
            </span>
          ) : (
            <span onClick={() => setshow(true)}>
              <AiFillEyeInvisible
                color={value.length >= 6 ? "#308805" : "#C7C5C5"}
                size={23}
              />
            </span>
          )}
        </span>

        <input
          onChange={setvalue}
          type={show ? "text" : type}
          value={value}
          className={`form-control  ${error && "error_border"}  ${
            classInput && classInput
          }`}
          id={name}
          name={name}
          aria-describedby={name}
        />
      </div>
      {registration && (
        <p className={`mt-1 ${error && "error_color"}`}>{t("formatPass")}</p>
      )}
    </div>
  );
}
