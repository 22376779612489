import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function TokenLang() {
  const { t, i18n } = useTranslation();

  const dataUser = JSON.parse(localStorage.getItem("login"));
  const updateLogin = useSelector((state) => state.login);
  const token = useCallback(() => {
    const token =
      dataUser && dataUser.data && dataUser.data.ecommerce_accessToken;
    return token;
  }, [updateLogin]);
  const lang = i18n.language;

  return [token(), lang];
}
