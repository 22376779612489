import React, { useEffect } from "react";
import "./style.css";
import FindParts from "../FindParts/FindParts";
import SecondBTN from "../BtnCustom/SecondBTN";
import { sliders_and_banners } from "../../services/action";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
export default function TopHomePageComp({ token, lang }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sliders_and_banners(lang, token, "banner", 1));
  }, [lang]);
  const banner = useSelector((state) => state.bannerHome);
  return (
    <>
      <div className="Hero_section position-relative">
        <div className="back_color position-absolute d-flex align-items-center">
          <div className="container-xxl ">
            <div className="row ">
              <div className="col-12 col-lg-6   text-center   w_h_Dynmic  align-items-center align-items-lg-start  d-flex  flex-column      mb-0 justify-content-center  ">
                {banner && banner.data && banner.data.length > 0 && (
                  <a
                    className="text-decoration-none"
                    href={banner.data[0].url}
                    target={banner.data[0].target}
                  >
                    <div
                      className="h-75 mb-5 "
                      dangerouslySetInnerHTML={{ __html: banner.data[0].html }}
                    />
                  </a>
                )}
              </div>

              <div className="col-12  col-lg-6 w_h_Dynmic mt-2    align-items-center   d-flex   justify-content-center   justify-content-lg-end    ">
                <FindParts />
              </div>
            </div>
          </div>
        </div>

        <img
          src={
            banner &&
            banner.data &&
            banner.data.length > 0 &&
            banner.data[0].image
          }
          className="w-100"
          alt=""
        />
      </div>
    </>
  );
}
