import React from "react";
import { useState } from "react";
import { MdRemove, MdAdd } from "react-icons/md";

const ToggleIcon = ({ idaction }) => {
  const [Active, setActive] = useState(true);
  return (
    <>
      <span
        data-bs-target={idaction}
        data-bs-toggle="collapse"
        className="ArrowToggleIcon"
        onClick={() => setActive(!Active)}
      >
        {Active ? <MdAdd size={27} /> : <MdRemove size={27} />}
      </span>
    </>
  );
};
export default ToggleIcon;
