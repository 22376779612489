import React, { useEffect } from "react";
import NavLinks from "./NavLink";

import "./style.css";
import DropUser from "./DropUser";
import TokenLang from "../../Hook/TokenLang";
import i18next from "i18next";
import FavCartIcon from "./FavCartIcon";
import SearchInput from "./SearchInput";
import { Link } from "react-router-dom";

import UsedNew from "./UsedNew";
import ChangeLang from "./ChangeLang";
export default function Nav({ nameUser, onlogout }) {
  const [token, lang] = TokenLang();

  useEffect(() => {
    let saveLang = JSON.parse(sessionStorage.getItem("lang"));
    i18next.changeLanguage(saveLang);
  }, [lang]);

  return (
    <div
      className="collapse navbar-collapse justify-content-between"
      id="navbarNavDropdown"
    >
      <div className="hidden_in_web  w_searsh  mx-2">
        <SearchInput />
      </div>

      <ul className=" navbar-nav p-2 ">
        <UsedNew />
        <NavLinks title={"Home"} path={"/HomePage"} />
        <NavLinks title={"All Products"} path={"/Categories"} />
        <ChangeLang customClass={"hidden_in_moble"} />
        <div className="px-2">
          {nameUser ? (
            <DropUser nameUser={nameUser} onlogout={onlogout} name={true} />
          ) : (
            <NavLinks title={"Login"} path={"/login"} />
          )}
        </div>
        <div className="hidden_in_web">
          <FavCartIcon />
        </div>
      </ul>
    </div>
  );
}
