import React from "react";
import "./style.css";
import { BsCheckCircleFill } from "react-icons/bs";
import { t } from "i18next";

export default function InputTextLabel({
  required,
  value,
  setvalue,
  label,
  type,
  name,
  classInput,
}) {
  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label fs-5">
        <span className="text-red fs-4 requert_star">*</span> {t(label)}
      </label>
      <div className="position-relative">
        <span className="    position-absolute checked_icon ">
          {required && (
            <BsCheckCircleFill
              color={value ? "#308805" : "#C7C5C5"}
              size={20}
            />
          )}
        </span>

        <input
          onChange={setvalue}
          type={type}
          value={value}
          className={`form-control ${classInput && classInput}`}
          id={name}
          name={name}
          aria-describedby={name}
          required={required}
        />
      </div>
    </div>
  );
}
