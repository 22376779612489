import React from "react";

export default function FilterBrands({ data, handleFormChange, BrandFilter }) {
  return (
    <>
      <form action="">
        {data &&
          data.map((item) => (
            <div
              key={item.id}
              className="form-check mt-1 d-flex p-0 justify-content-between  flex-row-reverse "
            >
              <div>
                <input
                  className="form-check-input "
                  type="checkbox"
                  value={item.id}
                  id={`brand_${item.id}`}
                  checked={BrandFilter.includes(item.id)}
                  onChange={handleFormChange}
                />
              </div>
              <div>
                <label
                  className="form-check-label "
                  htmlFor={`brand_${item.id}`}
                >
                  <img
                    src={item.image}
                    width={25}
                    height={25}
                    alt={item.name}
                    className="rounded-2"
                  />
                  <span className="mx-2"> {item.name} </span>
                </label>
              </div>
            </div>
          ))}
      </form>
    </>
  );
}
