import React from "react";
import "./style.css";
import ItemStep from "./ItemStep";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { trackingOrder } from "../../services/action";
import TokenLang from "../../Hook/TokenLang";
import SpinnerLoding from "../../lib/SpinnerLoding/SpinnerLoding";
export default function FollowOrderTrakening() {
  const param = useParams();
  const dispatch = useDispatch();
  const [token, lang] = TokenLang();
  useEffect(() => {
    dispatch(trackingOrder(param.id, token, lang));
  }, [lang]);
  const data = useSelector((state) => state.trackingOrder);

  return (
    <>
      <div
        className="container-xxl mb-5 h_foolow_order   d-flex
    justify-content-center"
      >
        {data.status ? (
          <section className="root">
            <div className="order-track">
              {data &&
                data.data.order_histories.map((item, index) => (
                  <ItemStep
                    activeCheck={
                      data.data.order_histories.length === index + 1
                        ? true
                        : false
                    }
                    data={item}
                    endState={
                      data.data.order_histories.length === index + 1
                        ? true
                        : false
                    }
                  />
                ))}
            </div>
          </section>
        ) : (
          <SpinnerLoding />
        )}
      </div>
    </>
  );
}
