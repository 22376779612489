import React from "react";
import ReactPaginate from "react-paginate";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import TokenLang from "../Hook/TokenLang";
import "./style.css";
export default function PaginateComp({ setnumberPage, pageCounts }) {
  const [token, lang] = TokenLang();
  return (
    <>
      <ReactPaginate
        nextLabel={
          lang == "ar" ? (
            <FaChevronLeft size={22} />
          ) : (
            <FaChevronRight size={22} />
          )
        }
        onPageChange={(e) => setnumberPage(e.selected + 1)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={0}
        pageCount={pageCounts}
        previousLabel={
          lang == "ar" ? (
            <FaChevronRight size={22} />
          ) : (
            <FaChevronLeft size={22} />
          )
        }
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}
