import React, { useEffect } from "react";
import CategoryCartSplashPage from "../../components/Cards/CategoryCartSplashPage";
import FooterSplashPage from "../../components/FooterSplashPage/FooterSplashPage";
import { useNavigate } from "react-router-dom";
import "./style.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import TokenLang from "../../Hook/TokenLang";
import {
  products_categories,
  sliders_and_banners,
} from "../../services/action";
import SpinnerLoding from "../../lib/SpinnerLoding/SpinnerLoding";
export default function SplashPage() {
  const Navigate = useNavigate();
  const onselect = (e) => {
    Navigate(`/HomePage?Catigoty_id=${e}`);
  };
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    className: "center",
    slidesToShow: 2,
    slidesToScroll: 2,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          slidesPerRow: 2,
        },
      },
    ],
  };
  const dispatch = useDispatch();
  const [token, lang] = TokenLang();

  useEffect(() => {
    dispatch(sliders_and_banners(lang, token, "SplashSlider", 2));
  }, []);
  const slider = useSelector((state) => state.SplashSlider);
  const backgroundImageUrl =
    slider && slider.status && slider.data && slider.data[0]?.image;
  const encodedBackgroundImageUrl =
    backgroundImageUrl && encodeURI(backgroundImageUrl);

  useEffect(() => {
    dispatch(products_categories(lang));
  }, [lang]);
  let productsCategories = useSelector((state) => state.products_categories);

  return (
    <>
      <section
        style={{
          backgroundImage: `url(${encodedBackgroundImageUrl})`,
        }}
        className="SplashPage"
      >
        <div className="bac_color_SplashPage">
          <div className="container-xxl  d-flex  flex-column   justify-content-between  h-100     align-items-center">
            <div className="row h-100 Slider_SplashPage  w-100 d-flex  align-items-center justify-content-center ">
              {productsCategories && productsCategories.status ? (
                <Slider {...settings}>
                  {productsCategories.data.map((item, index) => (
                    <CategoryCartSplashPage
                      color={index % 2}
                      key={item.id}
                      onselect={onselect}
                      data={item}
                    />
                  ))}
                </Slider>
              ) : (
                <div className="spinner_splash">
                  <SpinnerLoding />
                </div>
              )}
            </div>
            <div className="py-4 w-100">
              <FooterSplashPage />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
