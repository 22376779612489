import React from "react";
import { Navigate } from "react-router";
import TokenLang from "../../Hook/TokenLang";

const PrivateRoute = ({ children }) => {
  const [token, lang] = TokenLang();

  return token ? children : <Navigate to={"/Login"} />;
};
export default PrivateRoute;
