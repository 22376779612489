import React, { useEffect, useState } from "react";
import MapWithSearsh from "../../lib/MapWithSearsh";
import "./style.css";
export default function Maping({
  latitude,
  setlatitude,
  longitude,
  setlongitude,
}) {
  const options2 = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    const crd = pos.coords;
    setlongitude(crd.longitude);
    setlatitude(crd.latitude);
  }
  function error(err) {}
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success, error, options2);
  }, []);
  return (
    <>
      <div className="maping_style">
        {latitude != null && longitude != null && (
          <MapWithSearsh
            latitude={latitude}
            longitude={longitude}
            setlatitude={setlatitude}
            setlongitude={setlongitude}
          />
        )}
      </div>
    </>
  );
}
