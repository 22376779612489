import React from "react";
import "./style.css";
import { BsCheckCircleFill } from "react-icons/bs";
import InputPhoneCodeLib from "../../lib/InputPhoneCodeLib";
import { t } from "i18next";

export default function InputPhoneCode({
  required,
  value,
  setvalue,
  label,

  name,
}) {
  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label fs-5">
        <span className="text-red fs-4 requert_star">*</span> {t(label)}
      </label>
      <div className="position-relative dirction_static">
        <span className="position-absolute checked_icon_phone ">
          {required && (
            <BsCheckCircleFill
              color={value.length > 9 ? "#308805" : "#C7C5C5"}
              size={20}
            />
          )}
        </span>
        <InputPhoneCodeLib setvalue={setvalue} value={value} />
      </div>
    </div>
  );
}
