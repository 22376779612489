import { t } from "i18next";
import React from "react";
import { NavLink } from "react-router-dom";

export default function NavLinkCustom({
  label,
  path,
  classCustom,
  fun,
  children,
}) {
  return (
    <>
      <NavLink
        to={{ pathname: path, search: window.location.search }}
        onClick={fun && fun}
        className={classCustom}
      >
        {t(label)} {children}
      </NavLink>
    </>
  );
}
