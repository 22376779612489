import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./style.css";
import Nav from "./Nav";
import SearchInput from "./SearchInput";
import { HiViewList } from "react-icons/hi";
import DropUser from "./DropUser";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { user_favProductList } from "../../services/action";
import TokenLang from "../../Hook/TokenLang";
import FavCartIcon from "./FavCartIcon";
import { t } from "i18next";
import LinksCustom from "../../components/Links/LinksCustom";
import NavLinkCustom from "../../components/Links/NavLinkCustom";
import ChangeLang from "./ChangeLang";
export default function Navbar() {
  const [dataUser, setDataUser] = useState("");
  const login_data = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [token, lang] = TokenLang();
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("login"));
    let datauser = data ? data : login_data && login_data;
    if (datauser && datauser.data && datauser.data.ecommerce_accessToken) {
      setDataUser(datauser.data.user.first_name);
    }
  }, [login_data]);
  const logout = () => {
    localStorage.removeItem("login");
    dispatch({ type: "login", login: "" });
    Navigate("/Login");
    setDataUser("");
  };
  const updatafavProductList = useSelector((state) => state.favProducts);
  useEffect(() => {
    if (token) {
      dispatch(user_favProductList(lang, token));
    }
  }, [updatafavProductList]);
  const path = window.location.pathname;
  return (
    <>
      <nav className="navbar navbar-dark  Navbar_Color  py-0   navbar-expand-lg py-2 shadow ">
        <div className="container-xxl  ">
          <LinksCustom
            path={"/HomePage"}
            classCustom={"navbar-brand m-0  mx-2"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="103.528"
              height="64.258"
              viewBox="0 0 103.528 64.258"
            >
              <g
                id="Group_10885"
                data-name="Group 10885"
                transform="translate(0)"
              >
                <path
                  id="Path_261"
                  data-name="Path 261"
                  d="M677.622,655.721a2.681,2.681,0,0,0,0-5.361H645.39v5.362h8.355v27.21h5.362v-27.21h18.515Z"
                  transform="translate(-645.39 -629.942)"
                  fill="#d77804"
                />
                <path
                  id="Path_262"
                  data-name="Path 262"
                  d="M655.469,365.837h41.663V361H655.469c-5.26,0-9.539,3.86-9.539,8.605s4.279,8.605,9.539,8.605h22.764c4.385,0,7.953,3.218,7.953,7.174s-3.568,7.174-7.953,7.174H664.918v4.837h10.087l14.228,19.387h6.556l-14.56-19.694c5.9-1.229,10.318-6.011,10.318-11.7,0-6.623-5.973-12.011-13.315-12.011H655.469a3.788,3.788,0,1,1,0-7.536Z"
                  transform="translate(-645.885 -361)"
                  fill="#d77804"
                />
                <text
                  id="Ters"
                  transform="translate(53.528 31.258)"
                  fill="#d77804"
                  stroke="rgba(0,0,0,0)"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                  fontSize="24"
                  fontFamily="SegoeUI-Bold, Segoe UI"
                  fontWeight="700"
                >
                  <tspan x="0" y="26">
                    Ters
                  </tspan>
                </text>
              </g>
            </svg>
          </LinksCustom>
          <div className="navbar-toggler mx-2 border-0 d-flex align-items-center">
            <ChangeLang />
            <button
              className="navbar-toggler mx-2 border-0 "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <HiViewList size={27} color="#fff" />
            </button>
          </div>

          <div className="w-100    ">
            <Nav onlogout={logout} nameUser={dataUser} />
          </div>
        </div>
      </nav>
      {path !== "/" && path !== "" && (
        <div className="searsh_div">
          <div className="container-xxl py-3">
            <div className="row     align-items-center">
              <div className="col-8">
                <div className="shadow rounded-3 ">
                  <SearchInput />
                </div>
              </div>
              <div className="col-4">
                <FavCartIcon color={"#000"} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
