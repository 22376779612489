import React from "react";
import { MdAddShoppingCart } from "react-icons/md";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BsFillCartCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import CheckIFAddedhook from "../../Hook/CheckIFAddedhook";
import { AddFavHook } from "../../Hook/AddFavHook";
import { AddCart } from "../../services/action";
import { AddCartHooks } from "../../Hook/AddCartHooks";

export default function CardProduct({ data, classSlider }) {
  const Navigate = useNavigate();

  const dedalisProdeuc = () => {
    Navigate(`/Product/${data.id}${window.location.search}`);
  };
  const [checkIfAdded] = CheckIFAddedhook();
  const [addfavProduct] = AddFavHook();

  const [addCart] = AddCartHooks("Carts", AddCart);

  return (
    <>
      {data && (
        <div className={`  ${classSlider && classSlider}    position-relative`}>
          {data.product_kind == "newd" && (
            <div className="new_old position-absolute">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="63.515"
                height="86.199"
                viewBox="0 0 63.515 86.199"
              >
                <path
                  id="Icon_material-label"
                  data-name="Icon material-label"
                  d="M66.373,3.811A9.045,9.045,0,0,0,58.978,0L9.074.045A9.061,9.061,0,0,0,0,9.074V54.441a9.061,9.061,0,0,0,9.074,9.028l49.9.045A9.045,9.045,0,0,0,66.373,59.7L86.2,31.757Z"
                  transform="translate(63.515) rotate(90)"
                  fill="#d77804"
                />
                <text
                  x="31.757"
                  y="35.1"
                  fontSize="25"
                  fill="white"
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  {data.product_kind}
                </text>
              </svg>
            </div>
          )}

          <div
            className={` card   h-100 border-0 shadow rounded-3 CardProduct rounded-4`}
          >
            <img
              onClick={dedalisProdeuc}
              src={data.image}
              height={250}
              className="card-img-top shadow rounded-4"
              alt="test"
            />
            <div onClick={dedalisProdeuc} className="card-body pb-0">
              <p className="card-title fs-4">
                {" "}
                {data.name.slice(0, 15)}
                {data.name.length > 15 && "..."}
              </p>
              <p className="card-title fs-6">
                {" "}
                {data.desc.slice(0, 100)}
                {data.desc.length > 100 && "..."}
              </p>
            </div>
            <div className="p-2 border-0 pt-0">
              <div
                onClick={dedalisProdeuc}
                className="d-flex justify-content-between align-items-center"
              ></div>
              <div className="d-flex justify-content-between  align-items-center my-2 ">
                <h3 className="price_cart"> {data.final_price} L.E</h3>
                <div className="d-flex">
                  <button
                    onClick={() => addfavProduct(data)}
                    className="add_favorite mx-1 "
                  >
                    {data.fav_product === 1 ? (
                      <AiFillHeart color="#D77804" size={40} />
                    ) : (
                      <AiOutlineHeart size={40} color="#686868" />
                    )}
                  </button>
                  {checkIfAdded(data.id) ? (
                    <button
                      disabled
                      className="add_favorite main_color  fs-5 rounded-3 "
                    >
                      <BsFillCartCheckFill size={40} />
                    </button>
                  ) : (
                    <button
                      onClick={() => addCart(data)}
                      className="add_favorite   mx-1  b-0"
                    >
                      <MdAddShoppingCart size={40} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
